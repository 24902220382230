var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"setLocaleQuestion"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[(this.mode)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.correct-question",modifiers:{"correct-question":true}}],staticClass:"mr-2",attrs:{"variant":"outline-warning"}},[_vm._v("تصحيح سؤال")]):_vm._e(),(this.mode)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.correct-answer",modifiers:{"correct-answer":true}}],attrs:{"variant":"outline-warning"}},[_vm._v("تصحيح جواب")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"question-text"}},[_vm._v("نص السؤال")]),_c('EKInputTextarea',{staticClass:"mb-1",attrs:{"size":"sm","rules":[
                                                {
                                                    type: 'required',
                                                    message:
                                                        'نص السؤال مطلوب'
                                                }
                                            ],"name":"title"},model:{value:(_vm.questonsDto.title),callback:function ($$v) {_vm.$set(_vm.questonsDto, "title", $$v)},expression:"questonsDto.title"}})],1),(!_vm.typeTextAns)?_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"question-text"}},[_vm._v("نص الجواب")]),_vm._v(" "+_vm._s(_vm.questonsDto.answers[0])+" "),(
                                                _vm.questonsDto.answers ==
                                                    null ||
                                                    _vm.questonsDto.answers
                                                        .length == 0
                                            )?_c('EKInputTextarea',{staticClass:"mb-1",attrs:{"size":"sm","rules":[
                                                {
                                                    type: 'required',
                                                    message:
                                                        'نص الجواب مطلوب'
                                                }
                                            ],"name":"title"},model:{value:(_vm.questonsDto.answer),callback:function ($$v) {_vm.$set(_vm.questonsDto, "answer", $$v)},expression:"questonsDto.answer"}}):_c('EKInputTextarea',{staticClass:"mb-1",attrs:{"size":"sm","rules":[
                                                {
                                                    type: 'required',
                                                    message:
                                                        'نص الجواب مطلوب'
                                                }
                                            ],"name":"title"},model:{value:(
                                                _vm.questonsDto.answers[0].title
                                            ),callback:function ($$v) {_vm.$set(_vm.questonsDto.answers[0], "title", $$v)},expression:"\n                                                questonsDto.answers[0].title\n                                            "}})],1):_c('b-col',{attrs:{"cols":"12"}},[_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.answer-collapse",modifiers:{"answer-collapse":true}}],staticClass:"shadow mb-1 rounded border-0"},[_vm._v(" الأجوبة ")]),_c('b-collapse',{staticClass:"mb-1",attrs:{"id":"answer-collapse"}},[_c('b-list-group',[_c('b-form-radio-group',{model:{value:(
                                                        _vm.correctAnswearIndex
                                                    ),callback:function ($$v) {
                                                        _vm.correctAnswearIndex
                                                    =$$v},expression:"\n                                                        correctAnswearIndex\n                                                    "}},[_vm._l((_vm.questonsDto.answers),function(answer,index){return [(
                                                                !answer.correctionDate
                                                            )?_c('b-list-group-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                                                                'answer-collapse' +
                                                                    index
                                                            ),expression:"\n                                                                'answer-collapse' +\n                                                                    index\n                                                            "}],key:index,staticClass:"shadow border-0 mb-1 d-flex pr-0 rounded align-items-center"},[_c('b-form-radio',{attrs:{"variant":answer.correctAnswerId
                                                                        ? 'warning'
                                                                        : '',"value":index}}),_c('div',{staticClass:"w-100"},[_c('p',{staticClass:"m-0",class:{
                                                                        'text-warning':
                                                                            answer.correctAnswerId
                                                                    }},[_vm._v(" "+_vm._s(answer.title)+" ")]),(
                                                                        answer.correctAnswerId
                                                                    )?_c('b-collapse',{staticClass:"mb-1 w-100 mt-1",attrs:{"id":'answer-collapse' +
                                                                            index}},[_vm._l((_vm.questonsDto.answers),function(ans,i){return [(
                                                                                ans.id ==
                                                                                    answer.correctAnswerId
                                                                            )?_c('b-list-group-item',{key:i,staticClass:"d-flex"},[(
                                                                                    ans.isCorrect
                                                                                )?_c('unicon',{attrs:{"name":"check","fill":"#28c76f"}}):_c('unicon',{attrs:{"name":"times","fill":"#ea5455"}}),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(ans.title)+" ")])],1):_vm._e()]})],2):_vm._e()],1),_c('b-button',{staticClass:"ml-auto",attrs:{"size":"sm","variant":"link"},on:{"click":function($event){return _vm.removeAnswear(
                                                                        index,
                                                                        _vm.questonsDto.answers
                                                                    )}}},[_c('unicon',{attrs:{"name":"trash-alt","fill":"#FF6330"}})],1)],1):_vm._e()]})],2),_c('b-list-group-item',{staticClass:"mb-2 d-flex flex-column justify-content-center align-content-center border-0 shadow rounded"},[_c('label',{attrs:{"for":"question-text"}},[_vm._v("إجابة جديدة")]),_c('b-form-textarea',{attrs:{"size":"sm"},model:{value:(_vm.newAns),callback:function ($$v) {_vm.newAns=$$v},expression:"newAns"}}),_c('hr',{staticClass:"w-100"}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('small',{directives:[{name:"show",rawName:"v-show",value:(
                                                                _vm.questonsDto
                                                                    .answers
                                                                    .length <
                                                                    2
                                                            ),expression:"\n                                                                questonsDto\n                                                                    .answers\n                                                                    .length <\n                                                                    2\n                                                            "}],staticClass:"text-danger"},[_vm._v("يطلب على الأقل جوابين")]),_c('b-button',{staticClass:"ml-auto",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.addAnswear}},[_c('unicon',{attrs:{"name":"plus","fill":"#fff"}})],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EKInputText',{attrs:{"label":"تلميح السؤال","placeholder":"ادخل التلميح","name":"hint"},model:{value:(_vm.questonsDto.hint),callback:function ($$v) {_vm.$set(_vm.questonsDto, "hint", $$v)},expression:"questonsDto.hint"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EKInputSelect',{staticClass:"mb-2",attrs:{"label":"التصنيفات","placeholder":"اختر التصنيفات","options":_vm.tagsList,"name":"tags","multiple":""},model:{value:(_vm.questonsDto.tags),callback:function ($$v) {_vm.$set(_vm.questonsDto, "tags", $$v)},expression:"questonsDto.tags"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EKInputImage',{attrs:{"label":"صورة المادة","mul":"","title":"upload image","value":_vm.questonsDto.images},on:{"input":function($event){_vm.questonsDto.images = $event}},scopedSlots:_vm._u([{key:"preview",fn:function(ref){
                                                            var previewBase = ref.previewBase;
return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap border-top px-1 pt-1"},_vm._l((previewBase),function(base,index){return _c('img',{key:index,staticClass:"mb-1 border",staticStyle:{"object-fit":"cover"},attrs:{"src":base,"width":"200","height":"200"}})}),0)]}}])})],1)],1)],1)],1)],1),_c('b-col',[_c('ValidationObserver',{ref:"addQuestionLinks"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addQuestionLinks($event)}}},[_c('b-col',{staticClass:"border rounded pt-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('EKInputSelect',{attrs:{"label":"المادة","placeholder":"اختر المادة","options":_vm.subjectsList,"name":"subjectsList","clearable":true},model:{value:(
                                                    _vm.questionFilterDto.subjectID
                                                ),callback:function ($$v) {_vm.$set(_vm.questionFilterDto, "subjectID", $$v)},expression:"\n                                                    questionFilterDto.subjectID\n                                                "}})],1),(!_vm.typeTextAns)?_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('EKInputSelect',{attrs:{"label":"الأسئلة الكتابية","placeholder":"اختر الأسئلة الكتابية","options":_vm.filterdInterviewsBySubject,"multiple":"","rules":[
                                                    {
                                                        type: 'required',
                                                        message:
                                                            'الأسئلة الكتابية مطلوب'
                                                    }
                                                ],"name":"interviewsList","clearable":true},model:{value:(_vm.selectedInterviews),callback:function ($$v) {_vm.selectedInterviews=$$v},expression:"selectedInterviews"}})],1):_vm._e(),(_vm.typeTextAns)?_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('EKInputSelect',{attrs:{"label":"الدورات","placeholder":"اختر دورة","options":_vm.filterdCoursesBySubject,"multiple":"","name":"courcesList","clearable":true},model:{value:(_vm.selectedCourses),callback:function ($$v) {_vm.selectedCourses=$$v},expression:"selectedCourses"}})],1):_vm._e(),(_vm.typeTextAns)?_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('EKInputSelect',{attrs:{"label":"البنوك","placeholder":"اختر البنك","options":_vm.filterdBanksBySubject,"multiple":"","name":"banksList","clearable":true},model:{value:(_vm.selectedBanks),callback:function ($$v) {_vm.selectedBanks=$$v},expression:"selectedBanks"}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('EKInputText',{attrs:{"label":"الرقم","placeholder":"اختر الرقم","name":"select_assasa","rules":[
                                                    {
                                                        type: 'required',
                                                        message:
                                                            'الرقم مطلوب'
                                                    },
                                                    {
                                                        type: 'min_value:1',
                                                        message:
                                                            'الرقم ان يكون اكبر او يساوي ال 1'
                                                    }
                                                ],"type":"number"},model:{value:(_vm.questionOrder),callback:function ($$v) {_vm.questionOrder=$$v},expression:"questionOrder"}})],1),_c('b-col',{staticClass:"d-flex align-items-end pb-1"},[_c('b-button',{attrs:{"block":"","type":"submit","variant":"outline-primary"}},[_vm._v("إضافة")])],1),(_vm.typeTextAns)?_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-center mb-1 text-warning"},[_c('small',[_vm._v("يجب تحديد بنك واحد على الأقل او دورة واحدة")])])]):_vm._e()],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EKTable',{attrs:{"columns":_vm.banksExamsSubjectsHeader,"items":_vm.exams,"no_select":""},scopedSlots:_vm._u([{key:"items.dateCreated",fn:function(ref){
                                                var value = ref.value;
return [_vm._v(" "+_vm._s(new Date(value).toLocaleDateString( "en-GB" ))+" ")]}},{key:"items.delete",fn:function(ref){
                                                var props = ref.props;
return [_c('b-button',{staticClass:"ml-auto",attrs:{"size":"sm","variant":"link"},on:{"click":function($event){return _vm.exams.splice(
                                            props.row.originalIndex,
                                            1
                                        )}}},[_c('unicon',{attrs:{"name":"trash-alt","height":"18","width":"18"}})],1)]}}])})],1)],1)],1)],1),_c('b-card-footer',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.setLocaleQuestion}},[_vm._v(_vm._s(!this.mode ? "إضافة" : "تعديل"))]),_c('b-button',{attrs:{"variant":"outline-primary","to":_vm.prevRoute.name ? _vm.prevRoute.fullPath : '/questions'}},[_vm._v("تراجع")]),(this.mode)?_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"outline-danger"},on:{"click":_vm.onDelete}},[_vm._v("حذف")]):_vm._e()],1)],1),_c('b-modal',{attrs:{"size":"lg","id":"correct-question","ok-title":"تم","cancel-title":"إلغاء","title":"تصحيح السؤال"},on:{"show":_vm.getCorrectionQuestion,"hidden":_vm.resetCorrectQuestion,"ok":_vm.submitCorrectQuestion}},[_c('ValidationObserver',{ref:"correctQuestionObserver"},[_c('label',[_vm._v("نص السؤال")]),_c('EKInputTextarea',{staticClass:"mb-1",attrs:{"size":"sm","rules":[
                    {
                        type: 'required',
                        message: 'نص السؤال مطلوب'
                    }
                ],"name":"correctQuestion"},model:{value:(_vm.localeQuestonsDto.title),callback:function ($$v) {_vm.$set(_vm.localeQuestonsDto, "title", $$v)},expression:"localeQuestonsDto.title"}})],1)],1),_c('b-modal',{attrs:{"size":"lg","id":"correct-answer","ok-title":"تم","cancel-title":"إلغاء","title":"تصحيح الجواب"},on:{"show":_vm.getCorrectionQuestion,"hidden":_vm.resetCorrectQuestion,"ok":_vm.submitCorrectQuestion}},[_c('ValidationObserver',{ref:"correctAnsObserver"},[_c('b-row',[(!_vm.typeTextAns)?_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("نص الجواب")]),_c('EKInputTextarea',{staticClass:"mb-1",attrs:{"size":"sm","rules":[
                            {
                                type: 'required',
                                message: 'نص الجواب مطلوب'
                            }
                        ],"name":"answear"},model:{value:(_vm.localeQuestonsDto.textAns),callback:function ($$v) {_vm.$set(_vm.localeQuestonsDto, "textAns", $$v)},expression:"localeQuestonsDto.textAns"}})],1):_c('b-col',{attrs:{"cols":"12"}},[_c('b-list-group-item',{staticClass:"shadow mb-1 rounded border-0"},[_vm._v(" الأجوبة ")]),_c('b-list-group',[_c('b-form-radio-group',{model:{value:(_vm.localeQuestonsDto.correctAnswearIndex),callback:function ($$v) {_vm.$set(_vm.localeQuestonsDto, "correctAnswearIndex", $$v)},expression:"localeQuestonsDto.correctAnswearIndex"}},[_vm._l((_vm.localeQuestonsDto.answers),function(answer,index){return [(!answer.correctionDate)?_c('b-list-group-item',{key:index,staticClass:"shadow border-0 mb-1 d-flex pr-0 rounded align-items-center",class:answer.correctAnswerId
                                            ? 'border-warning'
                                            : 'border-0'},[_c('b-form-radio',{attrs:{"value":index}}),_c('EKInputTextarea',{staticClass:"w-100 mt-1 mr-1",attrs:{"size":"sm","rules":[
                                            {
                                                type: 'required',
                                                message: 'نص الجواب مطلوب'
                                            }
                                        ],"name":"answear"},model:{value:(answer.title),callback:function ($$v) {_vm.$set(answer, "title", $$v)},expression:"answer.title"}})],1):_vm._e()]})],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }